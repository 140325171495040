<template>
	<div class="">
		<!-- 弹框 -->
		<el-dialog
			title=""
			:visible.sync="dialog"
			width="50vw"
			:append-to-body="true"
			:before-close="handleClose"
			:close-on-click-modal="false"
		>  
            <div class="dislogBox">
                <div class="dialogClose" @click="cancel_dialog"></div>
                <div class="dialog-title"><span>{{dialogTitle?dialogTitle:'系统提示'}}</span>  </div>
                <div class="dialog-connent">
                    <ul class="dialog_form">
						<li class="form_item" v-for="(item, index) in dataList" :key="index">
							<label>{{ item.label || item.dataName }}</label>
							<!-- <input type="text" v-model="item.dataDetailed" /> -->
							<el-input-number v-model="item.dataDetailed" :controls="false" :min="0" ></el-input-number>
						</li>
					</ul>
                </div>
                <div class="dialog_btn" style="display: block">
                    <div style="margin-right: 50px" @click="cancel_dialog">取消</div>
                    <div @click="onSubmit">确定修改</div>
                </div>
            </div>
			<slot></slot>
		</el-dialog>
	</div>
</template>

<script>
import { batchDataDetail } from '@/api/dtdata.js';
export default {
	name: '',
	props: ['dialog', 'dialogTitle', 'dataList', 'prompt'],
	components: {},
	data() {
		return {};
	},
	created() {},
	methods: {
		onSubmit() {
			batchDataDetail(this.dataList).then((res) => {
				if (res.code == 200) {
					this.$message({
						message: '修改成功',
						type: 'success',
					});
					this.$parent.edit_dialog = false;
					this.$router.go(0); //刷新当前页面
				}
			});
		},
		cancel_dialog() {
			this.$parent.edit_dialog = false;
		},
		handleClose(done) {
			// done();
			this.$parent.edit_dialog = false;
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
	position: relative;
	margin: 0 auto 50px;
	border-radius: 2px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 99999;
	width: 50%;
	height: 66vh;
	// background: url();
	background: rgba(255, 255, 255, 0);
	// border: 1px solid teal !important;
	.el-dialog__header {
		/* padding: 20px 20px 10px; */
		padding-top: 4vh;
	}
	.el-dialog__body {
		padding: 0.2vw 0.2vh;
		color: #606266;
		font-size: 14px;
		word-break: break-all;
	}
	.el-dialog__headerbtn {
		width: 4vw;
		height: 5vh;
		// border: 1px solid teal !important;
		position: absolute;
		top: 5.5vh;
		right: -0.4vw;
		padding: 0;
		background: 0 0;
		border: none;
		outline: 0;
		opacity: 0;
		cursor: pointer;
		font-size: 16px;
		z-index: 999999;
	}
	.dialog-bg {
		position: relative;
	}
	.back-ground-image {
		width: 100%;
		height: 48vh;
		position: relative;
		// border: 1px solid tan;
	}
    .dislogBox{
        background: url(../../../../assets/image/zhfe/dialogBg.png) no-repeat;
        background-size: 100% 100%;
        padding: 0 1vw 3vh 1vw;
        position: relative;
         .dialogClose {
            width: 3vh;
            height: 3vh;
            position: absolute;
            top: -1.2vh;
            right: -0.7vw;
            cursor: pointer;
            background: url(../../../../assets/image/zhfe/dialogClose.png) no-repeat;
            background-size: 100% 100%;
        }
        .dialog-title{
            text-align: center;
            margin-bottom:5vh;
            >span{
                margin: 0 auto;
                min-width: 10vw;
                background-color: #CD80A2;
                border: 2px solid #fcd2e2;
                border-bottom-left-radius: 0.5vw;
                border-bottom-right-radius: 0.5vw;
                font-weight: 700;
                padding: 0 2vw;
                height: 5vh;
                line-height: 5vh;
                display: inline-block;
                vertical-align: middle;
                color: #fff;
                font-weight: 700;
                font-size: 1vw;
            }
        }
        .dialog-connent{
            height: 30vh;
			overflow-y: auto;
			padding-right: 1vw;
        }
    }
	.dialog-box {
		position: absolute;
		top: 6.5vh;
		right: 2.3vw;
		width: 91%;
		// height: 59vh;
		// overflow: hidden;
		// border: 1px solid thistle;
		.dialog-box-top {
			width: 100%;
			height: 6vh;
			line-height: 4vh;
			text-align: center;
			// border: 1px solid thistle;
			font-size: 0.9vw;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffd901;
		}
	}
}
.dialog_form {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.8vw;
	color: #007eff;
	.form_item {
		width: 22vw;
		height: 6vh;
		display: flex;
		align-items: center;
		label {
			width: 12vw;
			line-height: 1;
			margin-right: 0.5vw;
			text-align: right;
		}
		input {
			height: 4vh;
			width: 12vw;
			background: #011428;
			border: 1px solid #FFD4E4;
			border-radius: 2px;
			color: #ffffff;
			padding-left: 0.6vw;
		}
	}
}
.dialog_btn {
	// background: red;
	display: block;
	width: 100%;
	text-align: center;
	margin-top: 3vh;
	// position: absolute;
	// // top: 30vh;
	// bottom: -10vh;
	div {
		display: inline-block;
		width: 9.3vw;
		height: 4.6vh;
		line-height: 4.6vh;
		background: #002953;
		border: 1px solid #FFD4E4;
		border-radius: 0.2vw;
		color: #24d8d7;
		cursor: pointer;
        color: #FFD4E4;
	}
}
::v-deep .el-input-number .el-input{
	background: #002953;
	width: 10vw;
}
::v-deep .el-input-number.is-without-controls .el-input__inner{
	text-align: left;
	height: 4vh;
	width: 10vw;
	background: #011428;
	border: 1px solid #FFD4E4;
	border-radius: 2px;
	color: #ffffff;
	padding-left: 0.6vw;
}
</style>
