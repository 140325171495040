import request from '@/utils/request'

export function getList(params) {
    return request({
        url: '/szdt/person/list',
        method: 'GET',
        params
    })
}
export function getRecord(params) {
    return request({
        url: '/zhfe/record/list',
        method: 'get',
        params
    })
}

export function getRecordYear(params) {
    return request({
        url: '/zhfe/record/year',
        method: 'GET',
        params
    })
}

export function getbangfu(params) {
    return request({
        url: '/zhfe/detail/bangfu',
        method: 'GET',
        params
    })
}

export function getage(params) {
    return request({
        url: '/zhfe/zwmc/age',
        method: 'GET',
        params
    })
}

export function getlingban(params) {
    return request({
        url: '/zhfe/lingban/list',
        method: 'GET',
        params
    })
}

export function getzhferecordadminlist(query) {
    return request({
        url: '/zhfe/record/admin/list',
        method: 'GET',
        params: query
    })
}

export function addzhferecord(data) {
    return request({
        url: '/zhfe/record',
        method: 'POST',
        data
    })
}
export function savezhferecord(data) {
    return request({
        url: '/zhfe/record',
        method: 'PUT',
        data
    })
}

export function zhferecordimportTemplate() {
    return request({
        url: '/zhfe/record/importTemplate',
        method: 'get'
    })
}

export function zhferecordimportData(data) {
    return request({
        url: '/zhfe/record/importData',
        method: 'POST',
        data
    })
}

export function deletezhferecord(id) {
    return request({
        url: `/zhfe/record/${id}`,
        method: 'delete'
    })
}


export function getzhfedetaillist(params) {
    return request({
        url: '/zhfe/detail/list',
        method: 'GET',
        params
    })
}

export function getzhfedetail(id) {
    return request({
        url: `/zhfe/detail/${id}`,
        method: 'GET'
    })
}

export function deletezhfedetail(id) {
    return request({
        url: `/zhfe/detail/${id}`,
        method: 'DELETE'
    })
}

export function addzhfedetail(data) {
    return request({
        url: `/zhfe/detail`,
        method: 'post',
        data
    })
}

export function savezhfedetail(data) {
    return request({
        url: `/zhfe/detail`,
        method: 'put',
        data
    })
}

export function zhfedetailimportTemplate() {
    return request({
        url: `/zhfe/detail/importTemplate`,
        method: 'get'
    })
}

export function zhfedetailimportData(data) {
    return request({
        url: '/zhfe/detail/importData',
        method: 'POST',
        data
    })
}

export function szdtpersonimportTemplate(params) {
    return request({
        url: '/szdt/person/importTemplate',
        method: 'get',
    })
}

export function szdtpersonimportData(data) {
    return request({
        url: '/szdt/person/importData',
        method: 'POST',
        data
    })
}

export function getszdtpersonadminlist(params) {
    return request({
        url: '/szdt/person/admin/list',
        method: 'get',
        params
    })
}

export function addszdtperson(data) {
    return request({
        url: '/szdt/person',
        method: 'post',
        data
    })
}

export function savezdtperson(data) {
    return request({
        url: '/szdt/person',
        method: 'put',
        data
    })
}

export function getszdtpersonid(id) {
    return request({
        url: `/szdt/person/${id}`,
        method: 'get',
    })
}

export function deleteszdtperson(id) {
    return request({
        url: `/szdt/person/${id}`,
        method: 'DELETE'
    })
}

export function getNewDataRecord() {
    return request({
        url: '/zhfe/record/newDataRecord ',
        method: 'get'
    })
}