import { render, staticRenderFns } from "./bmMap.vue?vue&type=template&id=afc5e084&scoped=true&"
import script from "./bmMap.vue?vue&type=script&lang=js&"
export * from "./bmMap.vue?vue&type=script&lang=js&"
import style0 from "./bmMap.vue?vue&type=style&index=0&id=afc5e084&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afc5e084",
  null
  
)

export default component.exports