<template>
  <div>
    <!-- <Index></Index> -->
    <Home></Home>
  </div>
</template>

<script>
// import Index from "@/views/index/index.vue";
// import Home from "@/views/home/index.vue";
import Home from "@/views/zhfeHome/index.vue";
export default {
  components: {
    // Index,
    Home,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;

  #back-ground-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .header {
    width: 100%;
    height: 10vh;
    letter-spacing: 4vw;
    padding-left: 1vw;
    background: linear-gradient(to top, #c0d9ff, #fff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 2vw;
    text-align: center;
  }
}
</style>
