<!-- 妇女议事会 -->
<template>
    <div class='womenCouncil' @click="womanClick">
        <div class="wc-header">
            <div class="wc-item" v-for="(item, i) in womenCouncilNum" :key="i">
                <div class="wc-title"><span class="wc-num">{{item.dataValue}}</span>{{item.dataField1}}</div>
                <div class="wc-txt">{{item.dataName}}</div>
            </div>
            <div class="wc-item" >
                <div class="wc-title"><span class="wc-num">{{ytsNum}}</span>个</div>
                <div class="wc-txt">议题数</div>
            </div>
            <div class="wc-item" >
                <div class="wc-title"><span class="wc-num">{{allMannum}}</span>人</div>
                <div class="wc-txt">成员</div>
            </div>
        </div>
        <div class="womenEcharts" ref="womenEcharts"></div>
    </div>
</template>

<script>
export default {
    props:{
        womenCouncilNum:{
            type: Array,
            default:[]
        },
        womenCouncilData:{
            type: Array,
            default:[]
        },
        allMannum:{
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            dataList:[],
            ytsNum: 0,//议题数
        };
    },
    created(){
        this.dataList = this.womenCouncilData?this.womenCouncilData:[]
        this.dataList.forEach(item=>{
            this.ytsNum += item.dataDetailed*1
        })
    },
    methods:{
        getintnum(num){
            const len = String(num).length;
            const prec = 1
            const ceil = true
            if (len <= prec) { return num }; 
            const mult = Math.pow(10, prec);
            return ceil ? 
                Math.ceil(num / mult) * mult : 
                Math.floor(num / mult) * mult;
        },
        womanClick(){
            this.$emit('womanClick')
        }
    },
    mounted() {
        let ua = navigator.userAgent,
            isAndroid = /(?:Android)/.test(ua),
            isFireFox = /(?:Firefox)/.test(ua)
        let isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
        let baifenbi = [];
        let grayBar = [];
        let xingm = [];
        let maxNum = 0
        // 计算出最大值
        this.dataList.forEach(item=>{
            if(maxNum<item.dataDetailed*1){
                maxNum = item.dataDetailed*1
            }
        })
        this.dataList.forEach(item=>{
            baifenbi.push(item.dataDetailed)
            // grayBar.push(20)// 按照最高10来放置
            grayBar.push(this.getintnum(maxNum)) // 按照最大数字10的倍数值
            xingm.push(item.dataName)
        })
        
        let myChart = this.$echarts.init(this.$refs.womenEcharts);
        let option = {
          grid: {
            left: isTablet?'60%':'50%', //如果离左边太远就用这个......
            right: "15%",
            bottom: "0%",
            top: "0%",
            containLabel: true,
          },
          xAxis: [
            {
              show: false,
            },
            {
              show: false,
            },
          ],
          yAxis: {
            type: "category",
            axisLabel: {
              show: true, //让Y轴数据不显示
            },
            axisTick: {
              show: false, //隐藏Y轴刻度
            },
            axisLine: {
              show: false, //隐藏Y轴线段
            },
          },
          series: [
            {
                show: true,
                type: "bar",
                barGap: "-100%",
                barWidth: "20%", //统计条宽度
                itemStyle: {
                    normal: {
                        barBorderRadius: 150,
                        fontSize: 10,
                        color: "#4D2F48",
                    },
                },
                label: {
                    normal: {
                        show: true,
                        textStyle: {
                            color: "#fff", //百分比颜色
                            fontSize: isTablet?13:15,
                        },
                        position: "right",
                        formatter: function (data) {
                            return baifenbi[data.dataIndex];
                        },
                    },
                },
                data: grayBar,
            },
            //蓝条
            {
              show: true,
              type: "bar",
              barGap: "-100%",
              barWidth: "20%", //统计条宽度
              itemStyle: {
                normal: {
                  barBorderRadius: 40, //统计条弧度
                  color: {
                    colorStops: [
                      {
                        offset: 0,
                        color: "#FD5392", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#F86F64", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
              max: 1,
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    fontSize: isTablet?9:14,
                    color: "#fff", //百分比颜色
                  },
                  position: "left",
                  formatter: function (data) {
                    //富文本固定格式{colorName|这里填你想要写的内容}
                    return xingm[data.dataIndex];
                  },
                },
              },
              data: baifenbi,
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
            myChart.resize();
        });
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>