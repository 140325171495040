import request from '@/utils/request'

/** 查询地图坐标列表 */
export function listMap(query) {
    return request({
        url: '/system/map/list',
        method: 'get',
        params:query,
        headers: { isToken: false }
    })
}
// 删除坐标
export function delMap(data) {
    return request({
        url: '/system/map/'+data,
        method: 'delete',
    })
}
// 新增坐标
export function addMap(data) {
    return request({
        url: '/system/map',
        method: 'post',
        data,
    })
}
// 修改坐标
export function updateMap(data) {
    return request({
        url: '/system/map',
        method: 'put',
        data,
    })
}