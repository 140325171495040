<template>
    <div class='middle-bottom'>
        <div class="home-page middle-page">
            <div class="homepage-title" @click="awardClick">获奖情况</div>
            <div class="middle-awards" @click="companyUpdate(middlepageList[0], 0)">
                <div class="awards-title">{{middlepageList[0]?middlepageList[0].dataName:''}}</div>
                <div class="awards-box">
                    <div class="awards-page">
                        <span class="ap-box one">国家级</span>
                        <span class="ap-txt">{{middlepageList[0]?middlepageList[0].dataValue:0}}</span>
                    </div>
                    <div class="awards-page">
                        <span class="ap-box two">省级</span>
                        <span class="ap-txt">{{middlepageList[0]?middlepageList[0].dataValue1:0}}</span>
                    </div>
                    <div class="awards-page">
                        <span class="ap-box three">市级</span>
                        <span class="ap-txt">{{middlepageList[0]?middlepageList[0].dataValue2:0}}</span>
                    </div>
                </div>
            </div>
            <div class="middle-awards" @click="companyUpdate(middlepageList[1], 1)">
                <div class="awards-title">{{middlepageList[1]?middlepageList[1].dataName:''}}</div>
                <div class="awards-box">
                    <div class="awards-page">
                        <span class="ap-box one">国家级</span>
                        <span class="ap-txt">{{middlepageList[1]?middlepageList[1].dataValue:0}}</span>
                    </div>
                    <div class="awards-page">
                        <span class="ap-box two">省级</span>
                        <span class="ap-txt">{{middlepageList[1]?middlepageList[1].dataValue1:0}}</span>
                    </div>
                    <div class="awards-page">
                        <span class="ap-box three">市级</span>
                        <span class="ap-txt">{{middlepageList[1]?middlepageList[1].dataValue2:0}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-page middle-page" >
            <div class="homepage-title">儿童友好设施</div>
            <div class="children-list" @click="childrenUpdate">
                <div class="children-item" v-for="(item, i) in childrenData.slice(0,8)" :key="i">
                    <div class="ci-title">{{item.dataName}}</div>
                    <div class="ci-num">{{item.dataValue}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        middlepageList:{
            type: Array,
            default:[]
        },
        childrenData:{
            type: Array,
            default:[]
        },
    },
    data() {
        return {
            
        };
    },
    methods: {
        awardClick(){
            this.$emit('awardClick')
        },
        childrenUpdate(){
            this.$emit('childrenUpdate')
        },
        companyUpdate(datas, num){
            this.$emit('companyUpdate',datas, num)
        }
    },
    mounted() {
        
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>