<!-- 护苗行动 -->
<template>
    <div class='seedlingprotection'>
        <div class="stion-header">
            <div class="stion-item" v-for="(item,i) in seedlingData" :key="i">
                <div class="stion-num">{{item?.dataDetailed}}</div>
                <div class="stion-txt">{{item?.dataName}}</div>
            </div>
        </div>
        <div class="seedling" ref="seedling"></div>
    </div>
</template>

<script>
export default {
    props:{
        seedlingData:{
            type:Array,
            default:[]
        }
    },
    data() {
        return {
            ydata: [
                {name:'重点儿童',value: 12},
                {name:'重点妇儿',value: 24},
                {name:'重点家庭',value: 11},
                {name:'一对一结对帮扶“两类”女童',value: 20}
            ],
            xdata: ['重点儿童', '重点妇儿', '重点家庭','一对一结对帮扶“两类”女童'],
        };
    },
    created(){
        this.ydata = []
        this.xdata = []
        this.seedlingData.forEach(item => {
            this.ydata.push({
                name: item.dataName,
                value: item.dataDetailed
            })
            this.xdata.push(item.dataName)
        })
    },
    methods: {
        // 党员数据环形
        dangyuanData() {
            let ua = navigator.userAgent,
                isAndroid = /(?:Android)/.test(ua),
                isFireFox = /(?:Firefox)/.test(ua)
            let isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
            let ydata = this.ydata
            let xdata = this.xdata

            let myChart = this.$echarts.init(this.$refs.seedling);
            let color = ["#E36594", "#FEB1CE", "#8A1D1D", "#9A6F7F"];
            let option = {
                title: {
                    subtext: "",
                    textStyle: {
                        color: "#FFFFFF",
                        fontSize: 14, //文字的字体大小
                    },
                    left: "23%",
                    top: "85.5%",
                },
                color: color,
                legend: {
                    orient: "vertical",
                    top: "15%",
                    left: "0%",
                    bottom: "0%",
                    data: xdata,
                    itemWidth:  isTablet?5:10,
                    itemHeight: isTablet?5:10,
                    textStyle: {
                        //图例文字的样式
                        color: "#fff",
                        fontSize: isTablet?8.5:15,
                        padding: isTablet?1:5,
                    },
                    formatter: function (name) {
                        return "" + name;
                    },
                },
                series: [{
                    type: "pie",
                    clockwise: false, //饼图的扇区是否是顺时针排布
                    minAngle: 2, //最小的扇区角度（0 ~ 360）
                    radius: ["55%", "75%"],
                    center: ["75%", "45%"],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        //图形样式
                        normal: {
                            borderWidth: 1,
                        },
                    },
                    label: {
                        normal: {
                            show: false,
                            position: "center",
                            formatter: "{text|{b}}\n{c}",
                            rich: {
                                text: {
                                    color: "#fff",
                                    fontSize: isTablet?10:15,
                                    align: "center",
                                    verticalAlign: "middle",
                                    padding: 8,
                                },
                                value: {
                                    color: "#fff",
                                    fontSize: 15,
                                    align: "center",
                                    verticalAlign: "middle",
                                },
                            },
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: isTablet?10:16,
                                color: "#fff"
                            },
                        },
                    },
                    data: ydata,
                }],
            }

            myChart.setOption(option);
            myChart.currentIndex = 0;
            let time = undefined

            setTimeout(() => {
                myChart.on("mouseover", (params)=> {
                    clearInterval(time)
                    let allindex = []
                    ydata.forEach((item,i)=>{
                        allindex.push(i)
                    })
                    myChart.dispatchAction({
                        type: "downplay",
                        dataIndex: allindex,
                    });
                    myChart.dispatchAction({
                        type: "highlight",
                        seriesIndex: 0,
                        dataIndex: params.dataIndex,
                    })
                    myChart.currentIndex = params.dataIndex
                })

                myChart.on("mouseout", function () {
                    time = setInterval(function () {
                        let dataLen = option.series[0].data.length;
                        // 取消之前高亮的图形
                        let allindex = []
                        ydata.forEach((item,i)=>{
                            allindex.push(i)
                        })
                        myChart.dispatchAction({
                            type: "downplay",
                            dataIndex: allindex,
                        });
                        myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
                        // 高亮当前图形
                        myChart.dispatchAction({
                            type: "highlight",
                            seriesIndex: 0,
                            dataIndex: myChart.currentIndex,
                        });
                    }, 2000);
                });
                myChart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: 0,
                });   
            })

            time = setInterval(function () {

                let dataLen = option.series[0].data.length;
                // 取消之前高亮的图形
                let allindex = []
                ydata.forEach((item,i)=>{
                    allindex.push(i)
                })
                myChart.dispatchAction({
                    type: "downplay",
                    dataIndex: allindex,
                });
                myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
                // 高亮当前图形
                myChart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: myChart.currentIndex,
                });
            }, 2000);

            // 使用刚指定的配置项和数据显示图表。
            /*myChart.setOption(option);*/
            window.addEventListener("resize", function () {
                myChart.resize();
            });
        },
    },
    mounted() {
        setTimeout(() => {
           this.dangyuanData(); 
        });
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>