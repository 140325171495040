import Cookies from 'js-cookie'

const TokenKey = 'pass'
const readPass = 'fulian123'
const editPass = 'fulian456'

export {
  readPass, editPass
}

export function existSystem() {
  return Cookies.get(TokenKey) == editPass;
}
