import request from '@/utils/request'

// 我们的党建结对共建
export function getpartyList() {
    return request({
        url: '/szdt/wjdt/party/list',
        method: 'get',
        headers: { isToken: false }
    })
}

// 批量修改
export function updateparty(data) {
    return request({
      url: '/szdt/wjdt/party',
      method: 'put',
      data: data
    })
}
// 助销
export function getculturalList() {
    return request({
        url: '/szdt/wjdt/cultural/list',
        method: 'get',
    })
}

// 修改
export function updatecultural(data) {
    return request({
      url: '/szdt/wjdt/cultural ',
      method: 'put',
      data: data
    })
}

// 图片上传
export function uploadimg(data) {
    return request({
      url: '/common/upload',
      method: 'post',
      data: data
    })
}
// 获取执委管理数据
export function getStatistics(params) {
    return request({
      url: '/zhfe/zwmc/countByType',
      method: 'get',
      params
    })
}
// 护苗行动
export function getseedlingData(params) {
  return request({
    url: '/zhfe/detail/statistics',
    method: 'get',
    params
  })
}