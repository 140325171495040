<!-- 地图 -->
<template>
    <div class='mapCom'>
        <baidu-map
            class="map"
            :ak="ak"
            :zoom="zoom"
            :center="center"
            style="width: 100%; height: 100%"
            @ready="handler"
            :scroll-wheel-zoom="true"
        >
            <!-- 每个类别出现的点 -->
            <bm-marker v-for="(marker, index) of areaMarkers" :key="index + 'c'"
                :icon="rightIcon(marker)" :position="marker" @click="markerClick(marker)">
            </bm-marker>
            <!-- 特定的显示点 -->
            <bm-marker v-for="(marker, index) of givenmarkers" :key="index"
                :icon="givenmarkersIcon(marker)" :position="marker"
            >
                <bm-label
                    :content="marker.mapName"
                    :offset="{ width:  marker.icon?25:15, height: marker.icon?4:-2 }"
                    :class="{ hidden: isHidden }"
                ></bm-label>
            </bm-marker>
        </baidu-map>
        <!-- 左边类别 -->
        <div class="map-btn-view">
            <div class="btn-view" v-for="item in data" :key="item.dataId" @click="updateMarkers(item)">
                <img :src="getLdftIcon(item.dataDetailed)" class="btn-icon" />
                <span :class="markerType==item.dictValue?'active':''">{{ item.dataName }}（{{item.dataField1}}）</span>
            </div>
        </div>
    </div>
</template>

<script>
import BmMap from "@/components/bmMap";

// import BaiduMap from "vue-baidu-map/components/map/Map.vue";
// import BmMapType from "vue-baidu-map/components/controls/MapType";
// import BmMarker from "vue-baidu-map/components/overlays/Marker";
// import BmLabel from "vue-baidu-map/components/overlays/Label";

import BaiduMap from "@/components/vue-baidu-map/components/map/Map.vue";
import BmMapType from "@/components/vue-baidu-map/components/controls/MapType";
import BmMarker from "@/components/vue-baidu-map/components/overlays/Marker";
import BmLabel from "@/components/vue-baidu-map/components/overlays/Label";

import { listMap } from "@/api/map";
import { getDicts } from "@/api/data";
import mapJson from './mapJson/mapJson.json'
import defaultJson from './mapJson/defaltJson.json'
export default {
    components:{
        BmMap,
        BaiduMap,
        BmMapType,
        BmMarker,
        BmLabel
    },
    props:{
        maptypeList:{
            type: Array,
            default:[]
        }
    },
    data() {
        return {
            ak: "6aa74ff1de57fea84fbb086674d08883",
            center: { lng: 113.789582, lat: 23.033949 },
            zoom: 13,
            newZoom: 13,
            refreshData: 0,
            markers: [],
            areaMarkers: [],
            markerType: "",
            data:[],

            isHidden: false,
            map: undefined,
            mapStyle: [],
            givenmarkers:[],
            leftObje:undefined
        };
    },
    watch: {
        refreshData: {
            handler: function () {
                if (this.map) {
                    this.reload();
                }
            },
        },
    },
    methods: {
        givenmarkersIcon(types){
            if(types.icon && types.icon == 'zf'){
                return {
                    url: require("@/assets/icon/dqfwzx-min.png"),
                    size: {width: 30, height: 40},
                }
            }else{
                return {
                    // url: require("@/assets/icon/mapdz-a1.png"),
                    url: require("@/assets/image/idx-icon-dizhi.png"),
                    size: {width: 40, height: 40},
                }
            }
            
        },
        handler({ map }) {
            this.map = map;
            this.reload();
            map.setMapStyleV2({ styleJson: this.mapStyle });
        },
        reload() {
            let markerList = this.markers.concat(this.areaMarkers);
            let view = this.map.getViewport(eval(markerList));
            this.center = view.center
            if(this.leftObje&&this.leftObje.dataDetailed == 'fnzj'){
                this.zoom = view.zoom + 1
            }else{
                this.zoom = view.zoom
            }
        },

        getImg(type) {
            return require("@/assets/icon/" + type + ".png");
        },
        create() {
            getDicts("map_point").then((res) => {
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].remark) {
                        let center = JSON.parse(res.data[i].remark);
                        res.data[i]["center"] = center;
                        res.data[i]["zoom"] = center.zoom;
                    }
                }
                this.data = res.data;
            });
        },
        updateMarkers1(type) {
            listMap({ mapTyp: type }).then((res) => {
                this.markers = this.dealMarker(res.rows);
                this.refreshData++;
            });
        },
        updateMarkers(datas){
            let islogin = this.islogin()
            this.leftObje = datas
            if(islogin>0){
                this.markerType = datas.dataDetailed;
                this.areaMarkers = []
                let lodings = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                listMap({ mapTyp: datas.dataDetailed }).then((res) => {
                    this.areaMarkers = this.dealMarker(res.rows)
                    console.log(datas)
                    this.newZoom = datas.dataField2
                    this.refreshData++
                }).finally(()=>{
                    lodings.close();
                });
                // markersJson.forEach(item=>{
                //     if(item.type == type){
                //         this.areaMarkers.push(item)
                //     }
                // })
            }else{
                // 需要登录
                this.$emit('login')
            }
            
            // 重新获取对应的坐标
            // listMap({ mapTyp: type }).then((res) => {
            //     if (type == "xqzb") {
            //         this.areaMarkers = [];
            //     } else {
            //         if (this.areaMarkers.length == 0) {
            //             this.areaMarkers = this.markers.concat([]);
            //         }
            //     }
            //     this.markers = this.dealMarker(res.rows);
            //     this.refresh++;
            // });
        },
        // 判断是否登录
        islogin(){
            // 1为编辑，2为阅读，0为未登录
            const pass = this.$cookies.get('pass')
            let returndata = 0
            if(pass ==  this.readPass || pass == this.editPass){
                if(this.existSystem()){
                    returndata = 1
                }else{
                    returndata = 2
                }
            }else{
                returndata = 0
            }
            return returndata
        },
        dealMarker(markers) {
            for (let i = 0; i < markers.length; i++) {
                markers[i].lng = markers[i].longitude;
                markers[i].lat = markers[i].latitude;
            }
            return markers;
        },
        markerClick(datas) {
            // let data = {
            //     type: this.markerType,
            //     index: index
            // }
            this.$emit('markerClick',datas)
        },
        // 获取左边图标
        getLdftIcon(type){
            if(type){
                return require("@/assets/image/zhfe/icon/icon-"+type+".png");
            }
        },
        // 获取对应的图标
        rightIcon(item) {
            return {
                url: require("@/assets/image/zhfe/icon/icon-" + (item.mapIcon || this.markerType) + ".png"),
                size: { width: 34, height: 31 },
                
            };
        },
        // 获取左边的数据
        getLeftData(){
            // this.data = [
            //     {dictCode: 1,dictValue:'fnzj',dictLabel:'妇女之家',dictNum:46},
            //     {dictCode: 2,dictValue:'byl',dictLabel:'白玉兰',dictNum:8},
            //     {dictCode: 3,dictValue:'qcyg',dictLabel:'七彩阳光',dictNum:8},
            //     {dictCode: 4,dictValue:'zwgzs',dictLabel:'执委工作室',dictNum:11},
            //     {dictCode: 5,dictValue:'jfcs',dictLabel:'积分超市',dictNum:4},
            //     {dictCode: 6,dictValue:'fnwj',dictLabel:'妇女微家',dictNum:56},
            //     {dictCode: 7,dictValue:'jdw',dictLabel:'家调委',dictNum:1},
            //     {dictCode: 8,dictValue:'sxyz',dictLabel:'舒心驿站',dictNum:1},
            // ]
            this.data = this.maptypeList 
        }
    },
    created() {
        // this.create()
        // this.updateMarkers1(this.markerType);
        this.mapStyle = mapJson
        this.givenmarkers = defaultJson
        this.getLeftData()
        
    },
    mounted() {
        this.areaMarkers = []
        // datajson.forEach(item=>{
        //     if(item.type == 'fnzj'){
        //         this.areaMarkers.push(item)
        //     }
        // })
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
    .map{
        width: 100%;
        height: 100%;
    }
    #map-echarts-view {
        width: 100%;
        height: 100%;
        position: relative;
        //position: absolute;
        //top: 0;
        //left: 0;
    }
    .BMap_cpyCtrl {
        display: none;
    }
    ::v-deep .anchorBL {
        display: none;
    }
    ::v-deep .BMapLabel {
        background-color: transparent !important;
        border: 0 !important;
        font-size: .5vw !important;
        color:#04f3ee !important;
        opacity: .6;
    }
</style>