<!-- 智惠妇儿 -->
<template>
    <div class='main'>
        <div class="header">
            <img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png"/>
            <img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
            <span class="header-txt">智惠妇儿</span>
            <!-- 关闭 刷新 -->
            <div class="idx-middlesx-box3">
                <div class="idx-middle-1">
                    <img class="fh" src="@/assets/image/zhfe/icon/gb.png" v-show="gb" @click="close"/>
                    <img class="sx" src="@/assets/image/zhfe/icon/sx.png" alt="" @click="refresh" />
                </div>
            </div>
        </div>
        <div class="content">
            <div class="c-left">
                <div class="page-box">
                    <!-- 常住人口 -->
                    <div class="left-population" @click="populationClick">
                        <div class="lp-title">常住人口</div>
                        <div class="lp-num">
                            <span v-for="(item, i) in populationData" :key="i">{{item}}</span>
                        </div>
                        <div class="homepage-box">
                            <div class="onecontent">
                                <div class="content-title">户籍人口</div>
                                <div class="content-num">{{permanentNumList[0]}}</div>
                            </div>
                            <div class="onecontent">
                                <div class="content-title">辖区面积<span>(平方公里)</span></div>
                                <div class="content-num">{{permanentNumList[1]}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 组织建设 -->
                    <div class="home-page page-two">
                        <div class="homepage-title" @click="gotoLink('organization')">组织建设</div>
                        <statistics pageNum="1" :dataList="buildDataList" v-if="buildDataList.length>0" @statisticeUpdate="statisticeUpdate"></statistics>
                    </div>
                    <!-- 执委管理 -->
                    <div class="home-page page-two" >
                        <div class="homepage-title" @click="gotoLink('menbers')">执委管理</div>
                        <statistics pageNum="2" :dataList="executiveDataList" :countNum="countNum" v-if="executiveDataList.length>0" @committeeLink="gotoLink('menbers')"></statistics>
                    </div>
                </div>
                <div class="page-label"></div>
            </div>
            <div class="c-middle">
                <div class="middle-top">
                    <div class="middle-time">
                        <Date></Date>
                    </div>
                    <div class="middle-btn" v-if="isUpdateimg">
                        <el-button @click="gotoHomeimg">编辑信息</el-button>
                    </div>
                </div>
                <div class="middle-map">
                    <mapCom @login="mapLogin" @markerClick="markerClick" :maptypeList="maptypeList" v-if="maptypeList&&maptypeList.length>0"></mapCom>
                </div>
                <div class="middle-label"></div>
                <!-- 获奖情况、儿童友好设施 -->
                <middlepage :middlepageList="middlepageList" :childrenData="childrenData" v-if="childrenData&&childrenData.length>0" 
                    @awardClick="awardClick" @childrenUpdate="childrenUpdate" @companyUpdate="companyUpdate">
                </middlepage>
            </div>
            <div class="c-right">
                <div class="page-box">
                    <div class="home-page page-three">
                        <div class="homepage-title" @click="gotoLink('seedlings')">护苗行动</div>
                        <seedlingprotection :seedlingData="seedlingData" v-if="seedlingData.length>0"></seedlingprotection>
                    </div>
                    <div class="home-page page-four">
                        <div class="homepage-title" @click="gotoLink('womanMeet')">妇女议事会</div>
                        <womenCouncil :womenCouncilNum="womenCouncilNum" :womenCouncilData="womenCouncilData"
                            v-if="womenCouncilData.length>0" :allMannum="allMannum" @womanClick="womanClick">
                        </womenCouncil>
                    </div>
                    <div class="home-page page-five">
                        <div class="homepage-title" @click="gotoLink('volunteer')">巾帼志愿服务</div>
                        <volunteerService :womenVolunteersaList="womenVolunteersaList" v-if="womenVolunteersaList.length>0"
                            @showImg="gotoLink('volunteer')" @volunteerUpdate="volunteerUpdate"
                        ></volunteerService>
                        <!-- womenShowimg -->
                    </div>
                </div>
            </div>
        </div>
        <!-- 巾帼志愿服务弹窗 -->
        <el-dialog
            :visible.sync="womenShow"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="65vw"
            center
        >
            <div class="dialogTitleBox">
                <div class="dialogTitle">队伍架构图</div>
                <div class="dialogClose" @click="womenShow=false"></div>
            </div>
            <div class="dialogMain">
                <img src="../../assets/image/zhfe/womenimg.png">
            </div>
        </el-dialog>
        <!-- 地图内容点击弹出 -->
        <el-dialog
            :visible.sync="mapshow"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="65vw"
            center
        >
            <div class="dialogTitleBox">
                <div class="dialogTitle"><span>{{mapData.mapName?mapData.mapName:''}}</span></div>
                <div class="dialogClose" @click="mapshow=false"></div>
            </div>
            <div class="mapDialog">
                <div class="dm-left">{{mapData.remark}}</div>
                <div class="dm-right" v-if="mapimgList.length>0">
                    <div class="dmr-img" v-for="(item,i) in mapimgList" :key="i">
                        <img :src="item" />
                    </div>
                </div>
                <div class="dm-right" v-else>
                    <img src="@/assets/image/empty.png" class="dm-empty"/>
                </div>
            </div>
        </el-dialog>
        <!-- 登录 -->
        <loginDialog :loginVisible="loginVisible" @login="login"></loginDialog>
        <!-- 获奖情况编辑 -->
        <el-dialog
			title=""
			:visible.sync="middlepageDialog"
			width="50vw"
			:append-to-body="true"
			:before-close="cancel_dialog"
			:close-on-click-modal="false"
		>  
            <div class="dislogBox">
                <div class="dialogClose" @click="cancel_dialog"></div>
                <div class="dialog-title"><span>获奖情况编辑({{middlepageUpdate.dataName?middlepageUpdate.dataName:''}})</span></div>
                <div class="dialog-connent">
                    <ul class="dialog_form">
						<li class="form_item">
							<label>国家级</label>
							<!-- <input type="text" v-model="middlepageUpdate.dataDetailed"/> -->
                            <el-input-number v-model="middlepageUpdate.dataDetailed" :controls="false" :min="0" ></el-input-number>
						</li>
                        <li class="form_item">
							<label>省级</label>
							<!-- <input type="text" v-model="middlepageUpdate.dataField1"/> -->
                            <el-input-number v-model="middlepageUpdate.dataField1" :controls="false" :min="0" ></el-input-number>
						</li>
                        <li class="form_item">
							<label>市级</label>
							<!-- <input type="text" v-model="middlepageUpdate.dataField2" /> -->
                            <el-input-number v-model="middlepageUpdate.dataField2" :controls="false" :min="0" ></el-input-number>
						</li>
					</ul>
                </div>
                <div class="dialog_btn" style="display: block">
                    <div style="margin-right: 50px" @click="cancel_dialog">取消</div>
                    <div @click="onSubmit">确定修改</div>
                </div>
            </div>
			<slot></slot>
		</el-dialog>
        <!-- 字典编辑 -->
        <updateDialog 
            v-if="edit_dialog"
            :dialog="edit_dialog"
            :dialogTitle="dialogTitle"
            :dataList="dialogData"
        ></updateDialog>
    </div>
</template>

<script>
import Date from "@/views/home/components/date.vue";
import statistics from "./components/statistics.vue";
import seedlingprotection from "./components/seedlingprotection.vue";
import middlepage from "./components/middlepage.vue";
import womenCouncil from "./components/womenCouncil.vue";
import volunteerService from "./components/volunteerService.vue";
import mapCom from "./components/mapCom";
import loginDialog from "@/components/dialog/loginDialog.vue";
import {listData, getCountNum, batchDataDetail} from '@/api/dtdata.js';
import {getNumber } from '@/api/womanMeet.js';
import {getStatistics, getseedlingData } from '@/api/home.js';
import { getList, getNewDataRecord } from '@/api/seedlings';

import updateDialog from './components/dialog/dialog.vue'

export default {
    components: {
        Date,
        statistics,
        seedlingprotection,
        middlepage,
        womenCouncil,
        volunteerService,
        mapCom,
        loginDialog,
        updateDialog
    },
    data() {
        return {
            dialogData:[],
            loading: null,//加载
            populationData: [],//常住人口
            populationObj: [],//常住人口
            permanentNumList:[0,0],//户籍人口、辖区面积
            buildDataList:[],//组织建设
            executiveDataList:[],//执委管理
            middlepageList:[],//获奖情况
            middlepageNum:0,
            middlepageUpdate:{
                dataName: '',
                dataDetailed: 0,
                dataField1: 0,
                dataField2: 0
            },
            childrenData:[],//儿童友好设施
            womenVolunteersaList:[],//巾帼志愿服务
            womenCouncilNum:[],//妇女议事会数量
            womenCouncilData:[],//妇女议事会
            seedlingData:[],//护苗行动
            maptypeList:[],//地图类别列表

            gb: false,
            fullPage: true,
            loginVisible:false, //登录
            womenShow: false,//巾帼志愿服务弹窗
            password: '',//密码
            mapshow: false,//地图内容点击弹窗
            mapData:{
                "name":"",
                "type":"",
                "data":"",
                "imglist":[]
            },//地图弹窗数据
            mapimgList:[],
            countNum:0,
            edit_dialog: false,
            dialogTitle:'',
            middlepageDialog:false,
            isUpdateimg: false,
            allMannum:0,//妇女议事会总成员
        };
    },
    created(){
        this.cookies()
    },
    methods: {
        // 妇女议事会编辑
        womanClick(){
            let islogin = this.islogin()
            if(islogin == 0){
                this.loginVisible = true
            }else if(islogin == 1){
                this.dialogTitle = '妇女议事会'
                let newData = []
                this.womenCouncilNum.forEach(item=>{
                    item.dataDetailed = item.dataValue?item.dataValue:0
                    newData.push(item)
                })
                this.womenCouncilData.forEach(item=>{
                    newData.push(item)
                })
                this.dialogData = newData
                this.edit_dialog = true
            }
            
        },
        // 地图编辑跳转
        gotoHomeimg(){
            this.$router.push({path:'/mapPage'})
        },
        // 获奖情况确认按钮
        onSubmit(){
            this.middlepageList[this.middlepageNum] = this.middlepageUpdate
            batchDataDetail(this.middlepageList).then(res=>{
                if (res.code == 200) {
					this.$message({
						message: '修改成功',
						type: 'success',
					});
					this.middlepageDialog = false;
					this.$router.go(0); //刷新当前页面
				}
            })
        },
        // 获奖情况关闭弹窗
        cancel_dialog(){
            this.middlepageDialog = false
        },
        // 获奖情况
        companyUpdate(datas, num){
            this.middlepageNum = num
            let islogin = this.islogin()
            if(islogin == 0){
                this.loginVisible = true
            }else if(islogin == 1){
                datas.dataDetailed = datas.dataValue?datas.dataValue:0
                datas.dataField1 = datas.dataValue1?datas.dataValue1:0
                datas.dataField2 = datas.dataValue2?datas.dataValue2:0
                this.middlepageUpdate = datas
                this.middlepageDialog = true
            }
        },
        // 巾帼志愿服务编辑
        volunteerUpdate(){
            let islogin = this.islogin()
            if(islogin == 0){
                this.loginVisible = true
            }else if(islogin == 1){
                this.dialogTitle = '巾帼志愿服务'
                this.womenVolunteersaList.forEach(item=>{
                    item.dataDetailed = item.dataValue?item.dataValue:0
                })
                this.dialogData = this.womenVolunteersaList
                this.edit_dialog = true
            }
        },
        // 儿童友好设施
        childrenUpdate(){
            let islogin = this.islogin()
            if(islogin == 0){
                this.loginVisible = true
            }else if(islogin == 1){
                this.dialogTitle = '儿童友好设施'
                this.childrenData.forEach(item=>{
                    item.dataDetailed = item.dataValue?item.dataValue:0
                })
                this.dialogData = this.childrenData
                this.edit_dialog = true
            }
        },
        // 组织建设
        statisticeUpdate(){
            let islogin = this.islogin()
            if(islogin == 0){
                this.loginVisible = true
            }else if(islogin == 1){
                this.dialogTitle = '组织建设'
                this.buildDataList.forEach(item=>{
                    item.dataDetailed = item.dataValue?item.dataValue:0
                })
                this.dialogData = this.buildDataList
                this.edit_dialog = true
            }
        },
        // 常住人口编辑
        populationClick(){
            let islogin = this.islogin()
            if(islogin == 0){
                this.loginVisible = true
            }else if(islogin == 1){
                this.dialogTitle = '常住人口'
                this.populationObj.forEach(item=>{
                    item.dataDetailed = item.dataValue?item.dataValue:0
                })
                this.dialogData = this.populationObj
                this.edit_dialog = true
            }
        },
        // 获奖情况
        awardClick(){
            this.gotoLink('award')
        },
        // 地图点击需要登录
        mapLogin(){
            this.loginVisible = true
        },
        // 地图内容点击弹出
        markerClick(data){
            let islogin = this.islogin()
            if(islogin>0){
                this.mapData = data
                this.mapimgList = []
                let lodings = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                listData({
                    dataModule:"symap",
                    dataTyp: 'symap-'+data.mapTyp + '-' + data.mapId
                }).then(res=>{
                    if(res.code == 200){
                        if(data.remark || data.dataDetailed){
                            this.mapshow = true
                            res.rows.forEach(item=>{
                                this.mapimgList.push(item.dataDetailed)
                            })
                        }
                        
                        
                    }
                }).finally(()=>{
                    lodings.close();
                });
                
            }else{
                this.loginVisible = true
            }
        },
        getMapimg(item){
            return require("@/assets/image/zhfe/map/"+item)
        },
        // 跳转
        gotoLink(types){
            // types: womanMeet 妇女议事会，award获奖情况， organization 组织建设, menbers 执委管理, seedlings 护苗行动
            let islogin = this.islogin()
            if(islogin>0){
                if(types == 'womanMeet'){
                    this.$router.push({path:'/womanMeet'})
                }else if(types == 'award'){
                    this.$router.push({path:'/zherAward'})
                }else if(types == 'organization'){
                    this.$router.push({path:'/zhfeOrganization'})
                }else if(types == 'menbers'){
                    this.$router.push({path:'/menbers'})
                }else if(types == 'seedlings'){
                    this.$router.push({path:'/seedlings'})
                }else if(types == 'volunteer'){
                    this.$router.push({path:'/zhfeVolunteer'})
                }
            }else{
                this.loginVisible = true
            }
        },
        // 判断是否登录
        islogin(){
            // 1为编辑，2为阅读，0为未登录
            const pass = this.$cookies.get('pass')
            let returndata = 0
            if(pass ==  this.readPass || pass == this.editPass){
                if(this.existSystem()){
                    returndata = 1
                }else{
                    returndata = 2
                }
                // returndata = 2
            }else{
                returndata = 0
            }
            return returndata
        },
        // 显示巾帼志愿服务
        womenShowimg(){
            let islogin = this.islogin()
            if(islogin>0){
                this.womenShow = true
            }else{
                this.loginVisible = true
            }
        },
        
        // 登录
        login() {
            // 登录成功刷新页面
            this.$router.go(0); //刷新当前页面
        },
        cookies() {
            // 判断cookie
            if (`${this.readPass},${this.editPass}`.indexOf(this.$cookies.get("pass")) > -1 ) {
                this.fullPage = false;
                this.dialogVisible = false;
                this.gb = true;
            } else {
                this.fullPage = true;
                // this.dialogVisible = true;
                this.gb = false;
            }
        },
        refresh() {
            this.$router.go(0); //刷新当前页面
        },
        // 清除cookies
        close() {
            this.$cookies.remove("pass"); // return this
            this.$router.go(0); //刷新当前页面
        },
        // 常住人口
        async getpermanent(){
            await listData({
                dataModule:"sysj",
                dataTyp: 'sysj-rksj'
            }).then(res=>{
                if(res.code == 200){
                    if(res.rows.length>0){
                        res.rows.forEach(item => {
                            item.dataValue = item.dataDetailed
                            this.populationObj.push(item)
                        });
                        this.populationData = res.rows[0].dataDetailed.split("")
                        this.permanentNumList[0] = res.rows[1].dataDetailed
                        this.permanentNumList[1] = res.rows[2].dataDetailed
                    }
                }
            })
        },
        // 组织建设
        async getbuildDataList(){
            await listData({
                dataModule:"sysj",
                dataTyp: 'sysj-zzjs'
            }).then(res=>{
                if(res.code == 200){
                    res.rows.forEach(item => {
                        item.dataValue = item.dataDetailed
                        this.buildDataList.push(item)
                    });
                }
            })
        },
        // 执委管理
        async getexecutiveDataList(){
            await getStatistics().then(res=>{
                if(res.code == 200){
                    this.executiveDataList = res.data
                }
            })
        },
        // 获奖情况
        async getmiddlepageList(){
            await listData({
                dataModule:"sysj",
                dataTyp: 'sysj-hjqk'
            }).then(res=>{
                if(res.code == 200){
                    res.rows.forEach(item => {
                        item.dataValue = item.dataDetailed
                        item.dataValue1 = item.dataField1
                        item.dataValue2 = item.dataField2
                        this.middlepageList.push(item)
                    });
                }
            })
        },
        // 儿童友好设施
        async getchildrenData(){
            await listData({
                dataModule:"sysj",
                dataTyp: 'sysj-etyhss'
            }).then(res=>{
                if(res.code == 200){
                    res.rows.forEach(item => {
                        item.dataValue = item.dataDetailed
                        this.childrenData.push(item)
                    });
                }
            })
        },
        // 妇女议事会
        async getwomenCouncilNum(){
            await listData({
                dataModule:"sysj",
                dataTyp: 'sysj-fnysh'
            }).then(res=>{
                if(res.code == 200){
                    res.rows.forEach(item => {
                        item.dataValue = item.dataDetailed
                        this.womenCouncilNum.push(item)
                    });
                }
            })
        },
        async getwomenCouncilData(){
            await listData({
                dataModule:"sysj",
                dataTyp: 'sysj-fnysh-yt'
            }).then(res=>{
                if(res.code == 200){
                    this.womenCouncilData = res.rows
                }
            })
        },
        // 护苗行动
        async getseedlingData(){
            let datas = []
            const { data } = await getNewDataRecord()
            await getList(data).then(listres=>{
                if(listres.code == 200){
                    listres.data.forEach((item,i)=>{
                        datas[i] = {}
                        datas[i].dataName = item.personType
                        datas[i].dataDetailed = 0
                        item.list[0].list.forEach((nextitem,nexti)=>{
                            datas[i].dataDetailed += nextitem.personCount
                        })
                    })
                }
            })
            await getseedlingData(data).then(res=>{
                if(res.code == 200){
                    datas[3] = res.data[res.data.length - 1]
                    this.seedlingData = datas
                }
            })
        },
        // 巾帼志愿服务
        async getwomenVolunteersa(){
            await listData({
                dataModule:"sysj",
                dataTyp: 'sysj-jgzyfw'
            }).then(res=>{
                if(res.code == 200){
                    res.rows.forEach(item => {
                        item.dataValue = item.dataDetailed
                        this.womenVolunteersaList.push(item)
                    });
                }
            })
        },
        // 地图类别列表
        async getMaptypeList(){
            await listData({
                dataModule:"sysj",
                dataTyp: 'sysj-map'
            }).then(res=>{
                if(res.code == 200){
                    this.$nextTick(()=>{
                        this.maptypeList = res.rows
                    })
                }
            }).finally(()=>{
                this.loading.close();
            })
        },
        // 执委管理 总数
        async getCountNum(){
            await getCountNum().then(res=>{
                this.countNum = res.data?res.data.allNum*1:0
            })
        },
        // 妇女议事会总成员数
        getNumber(){
            getNumber({
                dataModule: "fnysh",
                dataTyp: 'fnysh-ryzcqk'
            }).then(res=>{
                if(res.code == 200){
                    this.allMannum = res.data
                }
            })
            
        }
    },
    mounted() {
        this.isUpdateimg = this.islogin() == 1?true:false
        this.loading = this.$loading({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        this.getNumber()
        this.getCountNum()
        this.getpermanent()// 常住人口
        this.getbuildDataList()//组织建设
        this.getexecutiveDataList()//执委管理
        this.getmiddlepageList()//获奖情况
        this.getchildrenData()//儿童友好设施
        this.getwomenCouncilNum()//妇女议事会
        this.getwomenCouncilData()//妇女议事会
        this.getseedlingData()//护苗行动
        this.getwomenVolunteersa()//巾帼志愿服务
        this.getMaptypeList()//地图类别列表
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>