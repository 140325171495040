import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueCookies from 'vue-cookies'
import { existSystem, readPass, editPass } from './utils/utils'

import 'element-ui/lib/theme-chalk/index.css';

import {
  getDicts,
  selectDictLabel,
  selectDefaultImg,
  defaultAvatar,
  getConfigKey,
  getConfigKeytel,
  getConfigKeypc,
  getConfigKeyiphone
} from "./api/data";
// Vue.config.productionTip = false
import echarts from "echarts";



Vue.prototype.$echarts = echarts;

Vue.use(ElementUI);
Vue.use(VueCookies)
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.getConfigKey = getConfigKeytel
Vue.prototype.getConfigKey = getConfigKeypc
Vue.prototype.getConfigKey = getConfigKeyiphone
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.defaultAvatar = defaultAvatar
Vue.prototype.selectDefaultImg = selectDefaultImg
Vue.prototype.existSystem = existSystem
Vue.prototype.readPass = readPass
Vue.prototype.editPass = editPass


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
