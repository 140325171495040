<!--  -->
<template>
    <div class=''>
        <div class="page-header" :class="pageNum==1?'':'yello'" @click="updateData">
            <img src="@/assets/image/zhfe/icon/user.png" v-if="pageNum==1"/>
            <img src="@/assets/image/zhfe/icon/useryello.png" v-else/>
            <span>{{pageNum==1?'妇女组织':'执委'}}</span>
            <span class="ph-num">{{pageNum==1?allData:countNum}}</span>
            <span>{{pageNum==1?'个':'人'}}</span>
        </div> 
        <div class="idx-Lbox-3" ref="statistics"></div>
    </div>
</template>

<script>
export default {
    props:{
        pageNum:{
            type: String,
            default: ''
        },
        dataList:{
            type: Array,
            default:[]
        },
        countNum:{
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            ydata: [],
            xdata: [],
            allData: 0
        };
    },
    methods: {
        updateData(){
            if(this.pageNum == 1){
                this.$emit('statisticeUpdate')
            }else{
                this.$emit('committeeLink')
            }
        },
        // 党员数据环形
        dangyuanData() {
            let ua = navigator.userAgent,
                isAndroid = /(?:Android)/.test(ua),
                isFireFox = /(?:Firefox)/.test(ua)
            let isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
            this.ydata = []
            this.xdata = []
            this.dataList.forEach(item => {
                this.allData += item.dataDetailed*1
                this.ydata.push({
                    name: item.dataName,
                    value: item.dataDetailed
                })
                this.xdata.push(item.dataName)
            });
            let ydata = this.ydata
            let xdata = this.xdata

            let myChart = this.$echarts.init(this.$refs.statistics);
            let color = ["#E36594", "#FEB1CE", "#8A1D1D", "#9A6F7F"];
            let option = {
                title: {
                    subtext: "",
                    textStyle: {
                        color: "#FFFFFF",
                        fontSize: 15, //文字的字体大小
                    },
                    left: "23%",
                    top: "85.5%",
                },
                color: color,
                legend: {
                    orient: "vertical",
                    top: "20%",
                    left: "55%",
                    bottom: "0%",
                    data: xdata,
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        //图例文字的样式
                        color: "#fff",
                        fontSize: isTablet?10:14,
                        padding: 5,
                    },
                    formatter: function (name) {
                        return "" + name;
                    },
                },
                series: [{
                    type: "pie",
                    clockwise: false, //饼图的扇区是否是顺时针排布
                    minAngle: 2, //最小的扇区角度（0 ~ 360）
                    radius: ["55%", "75%"],
                    center: ["30%", "53%"],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        //图形样式
                        normal: {
                            borderWidth: 1,
                        },
                    },
                    label: {
                        normal: {
                            show: false,
                            position: "center",
                            formatter: "{text|{b}}\n{c}",
                            rich: {
                                text: {
                                    color: "#fff",
                                    fontSize: isTablet?11:15,
                                    align: "center",
                                    verticalAlign: "middle",
                                    padding: 8,
                                },
                                value: {
                                    color: "#fff",
                                    fontSize: 15,
                                    align: "center",
                                    verticalAlign: "middle",
                                },
                            },
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: isTablet?16:22,
                                color: "#fff"
                            },
                        },
                    },
                    data: ydata,
                }],
            }

            myChart.setOption(option);
            myChart.currentIndex = 0;
            let time = undefined

            setTimeout(() => {
                myChart.on("mouseover", (params)=> {
                    clearInterval(time)
                    let allindex = []
                    ydata.forEach((item,i)=>{
                        allindex.push(i)
                    })
                    myChart.dispatchAction({
                        type: "downplay",
                        dataIndex: allindex,
                    });
                    myChart.dispatchAction({
                        type: "highlight",
                        seriesIndex: 0,
                        dataIndex: params.dataIndex,
                    })
                    myChart.currentIndex = params.dataIndex
                })

                myChart.on("mouseout", function () {
                    time = setInterval(function () {
                        let dataLen = option.series[0].data.length;
                        // 取消之前高亮的图形
                        let allindex = []
                        ydata.forEach((item,i)=>{
                            allindex.push(i)
                        })
                        myChart.dispatchAction({
                            type: "downplay",
                            dataIndex: allindex,
                        });
                        myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
                        // 高亮当前图形
                        myChart.dispatchAction({
                            type: "highlight",
                            seriesIndex: 0,
                            dataIndex: myChart.currentIndex,
                        });
                    }, 3000);
                });
                myChart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: 0,
                });   
            })

            time = setInterval(function () {

                let dataLen = option.series[0].data.length;
                // 取消之前高亮的图形
                let allindex = []
                ydata.forEach((item,i)=>{
                    allindex.push(i)
                })
                myChart.dispatchAction({
                    type: "downplay",
                    dataIndex: allindex,
                });
                myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
                // 高亮当前图形
                myChart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: myChart.currentIndex,
                });
            }, 3000);

            // 使用刚指定的配置项和数据显示图表。
            /*myChart.setOption(option);*/
            window.addEventListener("resize", function () {
                myChart.resize();
            });
        },
    },
    mounted(){
        setTimeout(() => {
            this.dangyuanData(); 
        });
    }
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>