<!-- 巾帼志愿服务 -->
<template>
    <div class='volunteerService'>
        <div class="vs-left" @click="volunteerUpdate">
            <div class="vsl-item" v-for="(item,i) in womenVolunteersaList" :key="i">
                <span>{{item.dataName}}</span><span class="vsl-all">{{item.dataValue}}</span>
            </div>
        </div>
        <div class="vs-right" @click="showImg">
            <div class="vs-img">
                <img src="@/assets/image/zhfe/volunteerServicenow.png" />
            </div>
            <div class="vs-txt">队伍架构略缩图</div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        womenVolunteersaList:{
            type: Array,
            default:[]
        }
    },
    data() {
        return {
            
        };
    },
    methods: {
        // 显示图片
        showImg(){
            this.$emit('showImg')
        },
        volunteerUpdate(){
            this.$emit('volunteerUpdate')
        }
    },
    mounted() {
        
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>