<!-- 登录 -->
<template>
    <el-dialog
            title=""
            :visible.sync="loginVisible"
            width="45%"
            top="25vh"
            :append-to-body="true"
            :close-on-click-modal="false"
        >
             <div class="dialogTitleBox">
                <div class="dialogClose" @click="handleClose"></div>
            </div>
            <div class="dialog-box">
                <div class="dialog-box-top">您没有查看权限，请输入密码才能查看哦</div>
                <div class="dialog-box-bottom">
                <div class="login-form">
                    <input type="password" v-model="password" placeholder="密码" id="password" @keyup.enter="login"/>
                    <button id="login" @click="login">登录</button>
                </div>
                </div>
            </div>
        </el-dialog>
</template>

<script>
export default {
    props:{
        loginVisible:{
            type: Boolean,
            default: false
        }
    },
    data() {
        
        return {
            password: ''
        };
    },
    methods: {
        // 登录
        login() {
            const pass = this.password; //获取到值
            if (pass ==  this.readPass || pass == this.editPass) {
                this.$message({
                    message: "登录成功",
                    type: "success",
                });
                this.$parent.loginVisible = false;
                this.$cookies.set("pass", pass); //return this
                this.$emit('login')
            } else {
                this.$message.error("密码错误，请重新输入");
            }
        },
        handleClose(){
          this.$parent.loginVisible = false;
        }
    },
    mounted() {
        
    },
}
</script>
<style lang='scss' scoped>
::v-deep .el-dialog {
    position: relative;
    margin: 0 auto;
    width: 50%;
    height: 45vh;
    background: url('../../assets/image/zhfe/dialogBg.png') no-repeat;
    background-size: 100% 100%;
    .el-dialog__headerbtn {
      width: 5vw;
      height: 7vh;
      // border: 1px solid teal !important;
      opacity: 0;
      position: absolute;
      top: 7vh;
      right: -2vw;
      padding: 0;
      background: 0 0;
      border: none;
      outline: 0;
      cursor: pointer;
      font-size: 16px;
      z-index: 999999;
    }
    .back-ground-image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 8vh;
      left: 1.2vw;
      // border: 1px solid tomato;
    }
    .dialog-box {
      position: absolute;
      top: 1%;
      left: 2%;
      width: 96%;
      height: 100%;
      overflow: hidden;
      // border: 1px solid tan;
      .dialog-box-top {
        width: 100%;
        height: 15vh;
        line-height: 16vh;
        text-align: center;
        // border: 1px solid thistle;
        font-size: 0.9vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
      }
      .dialog-box-bottom {
        width: 100%;
        height: 20vh;
        // border: 1px solid tan;
        margin-top: 0vh;
        position: absolute;
        top: 17vh;
        left: 0vw;
        .login-form {
          width: 100%;
          height: 20vh;
          // border: 1px solid tan;
          position: absolute;
          top: 0vh;
          left: 0vw;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          #password {
            width: 74%;
            height: 6vh;
            line-height:6vh;
            border-radius: 2vw;
            outline: none;
            color: #fff;
            text-indent: 2vw;
            background: #011428;
            border: 3px solid #FFD4E4;
            font-size: 0.9vw;
          }
          #login {
            width: 74%;
            height: 6vh;
            line-height: 6vh;
            border-radius: 2vw;
            outline: none;
            cursor: pointer;
            font-size: 0.9vw;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFD4E4;
            background: #032c64;
            border: 3px solid #FFD4E4;
          }
        }
      }
    }
}
</style>