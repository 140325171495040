import request from '@/utils/request'
// 妇女会议
export function getWomanMeetList(query) {
  return request({
    url: `/system/data/list`,
    method: 'get',
    params: query
  })
}
// 妇女会议獲取人数
export function getNumber(query) {
  return request({
    url: `/system/data/countByDataDetailed`,
    method: 'get',
    params: query
  })
}

// 批量修改
export function batchDataDetail(data) {
  return request({
    url: '/system/data/batch',
    method: 'put',
    data: data
  })
}

// 批量修改
export function add(data) {
  return request({
    url: '/system/data/create',
    method: 'post',
    data: data
  })
}

// 批量修改
export function update(data) {
  return request({
    url: '/system/data/update',
    method: 'put',
    data: data
  })
}

// 批量修改
export function del(id) {
  return request({
    url: '/system/data/delete/'+id,
    method: 'put',
  })
}